$font: Nunito, Helvetica, sans-serif;
$fontSize: 14px;
$fontSizeH1: 2.57rem; // 36px
$fontSizeH2: 2.14rem; // 30px
$fontSizeH3: 1.72rem; // 24px
$fontSizeH4: 1.29rem; // 18px
$fontSizeH5: 1.14rem; // 16px
$fontSizeP: 1rem; // 14px
$fontSizeSmall: 0.86rem; // 12px

$fontSizeH1Mobile: 2.14rem; // 30px
$fontSizeH2Mobile: 1.72rem; // 24px
$fontSizeH3Mobile: 1.57rem; // 22px
$fontSizeH4Mobile: 1.14rem; // 16px
$fontSizeH5Mobile: 1rem; // 14px
$fontSizeInputMobile: 1.14rem; // 16px

$mainBg: #f6f6fa;
$bannerBg: #e5e5ef;
$headingColor: #727491;
$disableColor: #d1d1d1;
$colorPrimaryBlueBrand: #514e86;
$colorSecondaryMidDarkGrey: #585a73;
$colorSecondaryOrange: #ff8a00;

$red: red;
$dark: #292929;
$grey: #4a4a4a;
$lGrey: #5a6778;
$dGrey: #252746;
$ash: #8f909b;
$orange: #ff4500;
$lOrange: #ffece5;
$dOrange: #e04405;
$purple: #444079;
$lPurple: #ecebf1;
$sky: #00abbc;
$blue: #0d0933;
$green: #008c9a;
$lGreen: #dcedf3;
$solutionsOrange: #e46f1e;

$purpleHover: #2f2b65;

$inputBorder: #d9d9e1;
$inputColor: $dark;
$placeholderColor: #a3a5bc;
$disclaimerColor: #444447;

$footerHeadColor: #c7c5e1;
$footerTextColor: #a4a1ce;

$colorTertiaryWhite: #ffffff;
$colorPrimaryHighlight: #fc5715;

$colorLightError: rgba(255, 122, 122, 0.5);
$colorError: rgba(255, 122, 122, 1);

$margin: 24px;
$mobileMargin: 12px;

$boxRadius: 9px;
$boxShadow: 0 0 24px rgba(black, 0.07);
$cardRadius: 24px;

$containerWidth: 1248px;
$largeContainerWidth: 1600px;
$sectionWidth: 1600px;
$headerHeight: 144px;
$headerSkinnyHeight: 150px;
$headerMobileHeight: 90px;
$slideItemWidth: 260px;
$slideItemLargeWidth: 310px;

$textWidthLimit: 690px;

$buttonHeight: 48px;
$buttonMobileHeight: 48px;
$buttonHeightSmall: 36px;
$buttonHeightLarge: 60px;
$searchInputHeight: 64px;

$modalBackground: rgba(black, 0.7);
$modalGelleryBackground: black;
