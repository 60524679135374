@import "~styles/globals.scss";
.header {
  display: flex;
  position: fixed;
  top: -200px;
  width: 100%;
  padding: 27px;
  transition: top 0.5s ease;
  background-color: white;
  z-index: 1;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  h3 {
    margin-right: 39px;
    margin-bottom: 0;
    font-size: $fontSizeH3;
  }

  .left {
    img {
      width: 211px;
      height: 48px;
    }
  }

  .right {
    display: flex;
    justify-content: space-between;
    align-items: center;

    button {
      margin-right: 12px;
    }
  }

  &.scrolled {
    top: 0;
  }
}
