@import 'variables';
@import 'responsive';
@import 'mixin';

.grid-container-desktop {
  display: grid;
  grid-template-columns: repeat(10, minmax(0, 1fr));
  margin-inline: auto;
  max-width: 1600px;
  width: 100%;

  @include mobile {
    align-items: center;
    display: flex;
    gap:1rem;
    justify-content: center;
  }

  .logo-width {
    margin: 1rem;
    max-width: 8rem;

    @include tablet {
      max-width: 7rem;
    }

    @include mobile {
      max-width: 6rem;
    }
  }

  .row-span-2 {
    grid-row: span 2 / span 2;
  }

  .col-span-2 {
    grid-column: span 2 / span 2;
  }

  .col-span-full {
    grid-column: 1 / -1;
  }

  .logo-padding {
    margin-block: auto;
  }

  .button-search {
    margin-block: auto;
    @include tablet {
      display: none;
    }
  }
}

.container-skinny {
  align-items: center;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  justify-content: center;
  padding: 1rem;
}

.container-skinny-bottom {
  align-items: center;
  display: flex;
  font-weight: 600;
  gap: 2rem;
  justify-content: center;
  text-align: center;
  width: 100%;

  a {
    color: white;
    font-size: 1.2rem;
    font-weight: 800;

    &:hover {
      color: $orange;
    }
  }
}

.container-skinny-top {
  align-items: center;
  display: flex;
  gap: 2rem;
  justify-content: space-between;
  max-width: 1200px;
  width: 100%;
}

.text-nowrap {
  text-wrap: nowrap;
}

.button-phone {
  align-items: center;
  display: flex;
  justify-content: center;
}

.icon-phone {
  min-width: 1.5rem;
}